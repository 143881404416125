import { request } from "./verb.services";
import { openNotificationWithIcon } from "../utils/Notification";
import { setLoading } from "redux/actions/loading.actions";

export const uploadFile = (file) => {
  return (dispatch) => {
    //   return new Promise((request, response) => {
    //     return request("Account/Upload", "post",)
    //       .then((response) => {
    //         dispatch((false));
    //         return response.data;
    //       })
    //       .catch((e) => {
    //         console.log(e);
    //         openNotificationWithIcon(
    //           "error",
    //           "Error!",
    //           e?.response?.data?.message || "Network error has occured"
    //         );
    //       });
    //   });
  };
};

export const logErrorToBackend = (data, showLoading) => {
  return (dispatch) => {
    if (showLoading) dispatch(setLoading(true));
    return request("Logging?logtypeId=3", "post", data, true)
      .then((res) => {
        // openNotificationWithIcon(
        //   "success",
        //   "Success!",
        //   "Issue reported to backend"
        // );
        return {
          status: "success",
          data: res.data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.message ||
            e?.message ||
            "Problem reporting error"
        );
      })
      .finally(() => {
        if (showLoading) dispatch(setLoading(false));
      });
  };
};
